export default {
  // methods: {
  //   hitungImt () {
  //     // alert('asdasda')
  //     // const tinggi = this.data.fisik.status_gizi.tinggi_badan
  //     // const berat = this.data.fisik.status_gizi.berat_badan

  //     // console.log(tinggi)
  //     // console.log(berat)

  //     // var tb = parseFloat(tinggi / 100)
  //     // var bb = berat
  //     // var imt = bb / (tb * tb)

  //     // this.data.fisik.status_gizi.imt = imt.toString()
  //     // console.log(imt)
  //   }
  // },
  demografi: {
    nama_pasien: {
      label: 'Nama Pasien',
      widget: 'wtext',
      data: null,
      col: 3
    },
    no_rekam_medis: {
      label: 'No Rekam Medis',
      widget: 'wtext',
      data: null,
      col: 3
    },
    tanggal_lahir: {
      label: 'Tangga Lahir',
      widget: 'wdate',
      data: null,
      col: 3
    },
    cara_bayar: {
      label: 'Cara Bayar',
      widget: 'wtext',
      data: [],
      col: 3
    },
    ruangan: {
      widget: 'wtext',
      label: 'Ruangan',
      // data: [
      //   { text: 'Laki-Laki', value: 'laki-laki' },
      //   { text: 'Wanita', value: 'wanita' }
      // ],
      data: null,
      col: 3
    },
    kamar_kelas: {
      widget: 'wtext',
      data: null,
      label: 'Kamar Kelas',
      col: 3
    },
    jenis_kelamin: {
      widget: 'wradio',
      data: [
        { text: 'Laki-Laki', value: 'laki-laki' },
        { text: 'Wanita', value: 'wanita' }
      ],
      col: 6
    },
    folio_id: {
      widget: 'wtext',
      data: null,
      col: 4,
      label: 'No Folio'
    },
    sub_unit_id: {
      widget: 'wtext',
      data: null,
      col: 4,
      label: 'Sub Unit Dokter'
    },
    reg_id: {
      widget: 'wtext',
      data: null,
      label: 'No Reg',
      col: 4
    }
  },
  form1: {
    p1: {
      widget: 'wlabelkiri',
      data: null,
      col: 3,
      label: 'Riwayat kehamilan'
    },
    p2: {
      widget: 'wtext',
      data: null,
      col: 2,
      label: ' ',
      property: { attrs: { prefix: 'G : ', type: 'number' } }
    },
    p3: {
      widget: 'wtext',
      data: null,
      col: 2,
      label: ' ',
      property: { attrs: { prefix: 'P : ', type: 'number' } }
    },
    p4: {
      widget: 'wtext',
      data: null,
      col: 2,
      label: ' ',
      property: { attrs: { prefix: 'A : ', type: 'number' } }
    },
    p5: {
      widget: 'wtext',
      data: null,
      col: 2,
      label: ' ',
      property: { attrs: { prefix: 'H : ', type: 'number' } }
    }
  },
  form3: {
    p0: {
      label: 'Tanda Tanda Vital',
      widget: 'wlabelkiri',
      col: 12
    },
    p1: {
      label: ' ',
      widget: 'wtext',
      col: 2,
      data: [],
      property: { attrs: { prefix: 'TD', suffix: 'mmHg', type: 'number' } }
    },
    p2: {
      label: ' ',
      widget: 'wtext',
      col: 2,
      data: [],
      property: { attrs: { prefix: 'N', suffix: 'Mnt', type: 'number' } }
    },
    p3: {
      label: ' ',
      widget: 'wtext',
      col: 2,
      data: [],
      property: { attrs: { prefix: 'RR', suffix: 'Mnt', type: 'number' } }
    },
    p4: {
      label: ' ',
      widget: 'wtext',
      col: 2,
      data: [],
      property: { attrs: { prefix: 's', suffix: '℃', type: 'number' } }
    },
    p5: {
      label: ' ',
      widget: 'wtext',
      col: 2,
      data: [],
      property: { attrs: { prefix: 'SPO2', type: 'number' } }
    },
    p6: {
      widget: 'wradio',
      label: ' ',
      data: [
        { text: 'Teratur', value: 'Teratur' },
        { text: 'Tidak Teratur', value: 'Tidak Teratur' },
        { text: 'Kuat', value: 'Kuat' },
        { text: 'Lemah', value: 'Lemah' }
      ],
      col: 12
    }
  },
  form4: {
    p1: {
      label: 'Perdarahan pervaginam',
      widget: 'wradio',
      data: [
        { text: 'Ya', value: 'Ya' },
        { text: 'Tidak', value: 'Tidak' }
      ],
      col: 12
    },
    p2: {
      label: 'Jumlah perdarahan',
      widget: 'wtext',
      col: 12,
      data: [],
      property: { attrs: { prefix: 'SPO2', type: 'number' } }
    },
    p3: {
      label: 'Kondisi perineum',
      widget: 'wtext',
      col: 12,
      data: [],
      property: { attrs: { prefix: 'SPO2', type: 'number' } }
    },
    p4: {
      label: 'Laserasi',
      widget: 'wradio',
      data: [
        { text: 'Ya', value: 'Ya' },
        { text: 'Tidak', value: 'Tidak' }
      ],
      col: 12
    },
    p4a: {
      label: 'Derajat  (Jika Laserasi : YA)',
      widget: 'wradio',
      data: [
        { text: 'I', value: 'I' },
        { text: 'II', value: 'II' },
        { text: 'III', value: 'III' },
        { text: 'IV', value: 'IV' }
      ],
      col: 12
    },
    p5: {
      label: 'Penyembuhan Luka Perineum',
      widget: 'wradio',
      data: [
        { text: 'Ya', value: 'Ya' },
        { text: 'Tidak', value: 'Tidak' }
      ],
      col: 12
    },
    p6: {
      label: 'Tanda Infeksi Perineum',
      widget: 'wradio',
      data: [
        { text: 'Ya', value: 'Ya' },
        { text: 'Tidak', value: 'Tidak' }
      ],
      col: 12
    },
    p6a: {
      label: 'Jika Tanda Infeksi Perineum : YA',
      widget: 'wradio',
      data: [
        { text: 'Bengkak', value: 'Bengkak' },
        { text: 'Merah', value: 'Merah' },
        { text: 'Panas', value: 'Panas' },
        { text: 'Berbau', value: 'Berbau' },
        { text: 'Mengeluarkan cairan', value: 'Mengeluarkan cairan' }
      ],
      col: 12
    },
    p7: {
      label: 'Tanda Infeksi Luka jahitan Sectio Caesaria',
      widget: 'wradio',
      data: [
        { text: 'Ya', value: 'Ya' },
        { text: 'Tidak', value: 'Tidak' }
      ],
      col: 12
    },
    p7a: {
      label: 'Jika Tanda Infeksi Luka jahitan Sectio Caesaria : YA',
      widget: 'wradio',
      data: [
        { text: 'Bengkak', value: 'Bengkak' },
        { text: 'Merah', value: 'Merah' },
        { text: 'Panas', value: 'Panas' },
        { text: 'Berbau', value: 'Berbau' },
        { text: 'Mengeluarkan cairan', value: 'Mengeluarkan cairan' }
      ],
      col: 12
    },
    p8: {
      label: 'Kondisi payudara',
      widget: 'wradio',
      data: [
        { text: 'TAK', value: 'TAK' },
        { text: 'Benjolan', value: 'Benjolan' },
        { text: 'Tampak seperti kulit jeruk', value: 'Tampak seperti kulit jeruk' },
        { text: 'Lain-lain', value: 'Lain-lain' }
      ],
      col: 12
    },
    p9: {
      label: 'Kontraksi Uterus',
      widget: 'wradio',
      data: [
        { text: 'Ya', value: 'Ya' },
        { text: 'Tidak', value: 'Tidak' }
      ],
      col: 12
    },
    p10: {
      label: 'Warna Lokhia',
      widget: 'wradio',
      data: [
        { text: 'Rubra', value: 'Rubra' },
        { text: 'Sangunolenta', value: 'Sangunolenta' },
        { text: 'Serosa', value: 'Serosa' },
        { text: 'Alba', value: 'Alba' }
      ],
      col: 12
    },
    p11: {
      label: 'Bau Lokhia',
      widget: 'wradio',
      data: [
        { text: 'Normal', value: 'Normal' },
        { text: 'Tidak normal', value: 'Tidak normal' }
      ],
      col: 12
    },
    p12: {
      label: 'Produksi ASI',
      widget: 'wradio',
      data: [
        { text: 'Ada', value: 'Ada' },
        { text: 'Tidak', value: 'Tidak' }
      ],
      col: 12
    },
    p13: {
      label: 'Buang Air Kecil',
      widget: 'wradio',
      data: [
        { text: 'Normal', value: 'Normal' },
        { text: 'Tidak normal', value: 'Tidak normal' }
      ],
      col: 12
    },
    p13a: {
      label: 'Jika Buang Air Kecil Tidak Normal',
      widget: 'wradio',
      data: [
        { text: 'Inkontinesia Urine', value: 'Inkontinesia Urine' },
        { text: 'Hematuria', value: 'Hematuria' },
        { text: 'Frekuensi', value: 'Frekuensi' },
        { text: 'Urostomy', value: 'Urostomy' },
        { text: 'Disuria', value: 'Disuria' },
        { text: 'Urine Menetes', value: 'Urine Menetes' },
        { text: 'Nocturia/Ssering Kencing Mala', value: 'Nocturia/Ssering Kencing Mala' },
        { text: 'Keteter, Tipe', value: null, field: 'auto' },
        { text: 'Ukuran Keteter', value: 'Ukuran Keteter' }
      ],
      col: 12
    }
  },
  form5: {
    p1: {
      label: 'Buang Air Besar',
      widget: 'wradio',
      data: [
        { text: 'Normal', value: 'Normal' },
        { text: 'Tidak normal', value: 'Tidak normal' }
      ],
      col: 12
    },
    p1a: {
      label: 'Jika Buang Air Besar Tidak Normal',
      widget: 'wradio',
      data: [
        { text: 'Konstipasi', value: 'Konstipasi' },
        { text: 'Diare  Frekuensi BAB/Hari', value: 'Diare  Frekuensi BAB/Hari' },
        { text: 'Inkontinesia Alvi', value: 'Inkontinesia Alvi' },
        { text: 'Liestomy ', value: null, field: 'auto' }
      ],
      col: 12
    },
    p3: {
      label: 'Konseling',
      widget: 'wradio',
      data: [
        { text: 'Ada', value: 'Ada' },
        { text: 'Tidak', value: 'Tidak' }
      ],
      col: 12
    },
    p4: {
      label: 'Laboratorium',
      widget: 'wradio',
      data: [
        { text: 'Ada', value: 'Ada' },
        { text: 'Tidak', value: 'Tidak' }
      ],
      col: 12
    },
    p5: {
      label: ' - Pemeriksaan laboratorium',
      widget: 'wtext',
      data: [],
      col: 12
    },
    p6: {
      label: ' - Data specimen',
      widget: 'wtext',
      data: [],
      col: 12
    },
    p7: {
      label: ' - Hasil Pemeriksaan laboratorium',
      widget: 'wtext',
      data: [],
      col: 12
    },
    p8: {
      label: ' - Laporan Pemeriksaan Penunjang Laboratorium',
      widget: 'wtext',
      data: [],
      col: 12
    },
    p9: {
      label: ' - Diagnose utama',
      widget: 'wtext',
      data: [],
      col: 12
    },
    p10: {
      label: ' - Diagnosa Sekunder ',
      widget: 'wtext',
      data: [],
      col: 12
    },
    p11: {
      label: 'Tindakan',
      widget: 'wtext',
      data: [],
      col: 12
    },
    p12: {
      label: 'Farmasi - Alergi Obat',
      widget: 'wtext',
      data: [],
      col: 12
    },
    p13: {
      label: 'Farmasi - Peresepan Obat',
      widget: 'wtext',
      data: [],
      col: 12
    },
    p14: {
      label: 'Farmasi - Pengeluaran Obat',
      widget: 'wtext',
      data: [],
      col: 12
    },
    p15: {
      label: 'Rencana Tindak Lanjut',
      widget: 'wradio',
      data: [
        { text: 'Tidak Perlu Kontrol', value: 'Tidak Perlu Kontrol' },
        { text: 'Kembali ke faskes', value: 'Kembali ke faskes' },
        { text: 'Kontrol Ulang Ke Poliklinik (Tanggal)', value: null, field: 'auto' }
      ],
      col: 12
    },
    p16: {
      label: 'Kondisi Saat Meninggalkan RS',
      widget: 'wradio',
      data: [
        { text: 'Sembuh', value: 'Sembuh' },
        { text: 'Membaik', value: 'Membaik' },
        { text: 'Belum Sembuh', value: 'Belum Sembuh' },
        { text: 'Meninggal > 48 jam', value: 'Meninggal > 48 jam' },
        { text: 'Meninggal < 48 jam', value: 'Meninggal < 48 jam' }
      ],
      col: 12
    },
    p17: {
      label: 'Cara Keluar RS',
      widget: 'wradio',
      data: [
        { text: 'Izin Dokter', value: 'Izin Dokter' },
        { text: 'PAPS', value: 'PAPS' },
        { text: 'Meninggal', value: 'Meninggal' },
        { text: 'Dirujuk Ke RS Lain', value: null, field: 'auto' }
      ],
      col: 12
    },
    p19: {
      label: 'Mulai Nifas (Tanggal)',
      widget: 'wdatenormal',
      col: 6,
      data: []
    },
    p20: {
      label: 'Mulai Nifas (Jam)',
      widget: 'wtext',
      col: 6,
      data: [],
      property: { attrs: { type: 'number' } }
    },
    p21: {
      label: 'Pulang (Tanggal)',
      widget: 'wdatenormal',
      col: 6,
      data: []
    },
    p22: {
      label: 'Pulang (Jam)',
      widget: 'wtext',
      col: 6,
      data: [],
      property: { attrs: { type: 'number' } }
    }
  }
}
